@font-face {
  font-family: "Effra";
  src: url("../assets/fonts/effra/Effra-LightItalic.eot");
  src: local("Effra Light Italic"), local("Effra-LightItalic"),
    url("../assets/fonts/effra/Effra-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/effra/Effra-LightItalic.woff2") format("woff2"),
    url("../assets/fonts/effra/Effra-LightItalic.woff") format("woff"),
    url("../assets/fonts/effra/Effra-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Effra";
  src: url("../assets/fonts/effra/Effra.eot");
  src: local("Effra"),
    url("../assets/fonts/effra/Effra.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/effra/Effra.woff2") format("woff2"),
    url("../assets/fonts/effra/Effra.woff") format("woff"),
    url("../assets/fonts/effra/Effra.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Effra";
  src: url("../assets/fonts/effra/Effra-Bold.eot");
  src: local("Effra Bold"), local("Effra-Bold"),
    url("../assets/fonts/effra/Effra-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/effra/Effra-Bold.woff2") format("woff2"),
    url("../assets/fonts/effra/Effra-Bold.woff") format("woff"),
    url("../assets/fonts/effra/Effra-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Effra";
  src: url("../assets/fonts/effra/Effra-Light.eot");
  src: local("Effra Light"), local("Effra-Light"),
    url("../assets/fonts/effra/Effra-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/effra/Effra-Light.woff2") format("woff2"),
    url("../assets/fonts/effra/Effra-Light.woff") format("woff"),
    url("../assets/fonts/effra/Effra-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Effra";
  src: url("../assets/fonts/effra/Effra-MediumItalic.eot");
  src: local("Effra Medium Italic"), local("Effra-MediumItalic"),
    url("../assets/fonts/effra/Effra-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/effra/Effra-MediumItalic.woff2") format("woff2"),
    url("../assets/fonts/effra/Effra-MediumItalic.woff") format("woff"),
    url("../assets/fonts/effra/Effra-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Effra";
  src: url("../assets/fonts/effra/EffraHeavy-Italic.eot");
  src: local("Effra Heavy Italic"), local("EffraHeavy-Italic"),
    url("../assets/fonts/effra/EffraHeavy-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/effra/EffraHeavy-Italic.woff2") format("woff2"),
    url("../assets/fonts/effra/EffraHeavy-Italic.woff") format("woff"),
    url("../assets/fonts/effra/EffraHeavy-Italic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Effra";
  src: url("../assets/fonts/effra/Effra-Italic.eot");
  src: local("Effra Italic"), local("Effra-Italic"),
    url("../assets/fonts/effra/Effra-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/effra/Effra-Italic.woff2") format("woff2"),
    url("../assets/fonts/effra/Effra-Italic.woff") format("woff"),
    url("../assets/fonts/effra/Effra-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Effra";
  src: url("../assets/fonts/effra/Effra-BoldItalic.eot");
  src: local("Effra Bold Italic"), local("Effra-BoldItalic"),
    url("../assets/fonts/effra/Effra-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/effra/Effra-BoldItalic.woff2") format("woff2"),
    url("../assets/fonts/effra/Effra-BoldItalic.woff") format("woff"),
    url("../assets/fonts/effra/Effra-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Effra";
  src: url("../assets/fonts/effra/Effra-Medium.eot");
  src: local("Effra Medium"), local("Effra-Medium"),
    url("../assets/fonts/effra/Effra-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/effra/Effra-Medium.woff2") format("woff2"),
    url("../assets/fonts/effra/Effra-Medium.woff") format("woff"),
    url("../assets/fonts/effra/Effra-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Effra";
  src: url("../assets/fonts/effra/Effra-Heavy.eot");
  src: local("Effra Heavy"), local("Effra-Heavy"),
    url("../assets/fonts/effra/Effra-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/effra/Effra-Heavy.woff2") format("woff2"),
    url("../assets/fonts/effra/Effra-Heavy.woff") format("woff"),
    url("../assets/fonts/effra/Effra-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
